@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Regular.ttf);
    font-weight: normal;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Thin.ttf);
    font-weight: 100;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-ThinItalic.ttf);
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-ExtraLight.ttf);
    font-weight: 200;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-ExtraLightItalic.ttf);
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Italic.ttf);
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-SemiBoldItalic.ttf);
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-ExtraBold.ttf);
    font-weight: 800;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-ExtraBoldItalic.ttf);
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-Black.ttf);
    font-weight: 900;
}
@font-face {
    font-family: WorkSans;
    src: url(WorkSans-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
}