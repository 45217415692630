@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../icons/simplelineicons/css/simple-line-icons.css";

@import "../fonts/WorkSans/WorkSans.scss";

h1, h2, h3, h4, h5, h6, .font-worksans{
    font-family: "Work Sans", Arial, Helvetica, sans-serif;
    font-optical-sizing: auto;
}

.font-dmserif{
    font-family: 'DM Serif Display', 'Times New Roman', Times, serif;
    font-weight: 400;

}


:root{
    --corner-width: 8vw;
    --corner-angle: 32deg;
    --tan-corner-angle: 0.624869;
}

:root{
    --corner-height: calc(var(--corner-width) * var(--tan-corner-angle));
}

.after\:footer-corner::after{
    content: " ";
    background-color: inherit;
    transform: skewY(var(--corner-angle));
    left:-1px;
    position: absolute;
    width: var(--corner-width);
    height: var(--corner-height);
    top: calc(-1 * var(--corner-width) * var(--tan-corner-angle) / 2);
}

.after\:header-corner::after{
    background-color: inherit;
    transform: skewY(var(--corner-angle));
    content: " ";
    left: -1px;
    position: absolute;
    width: var(--corner-width);
    height: var(--corner-height);
    bottom: calc(-1 * var(--corner-width) * var(--tan-corner-angle) / 2);
}
.before\:footer-corner::before{
    background-color: inherit;
    transform: skewY(var(--corner-angle));
    content: " ";
    left: -1px;
    position: absolute;
    width: var(--corner-width);
    height: var(--corner-height);
    top: calc(-1 * var(--corner-width) * var(--tan-corner-angle) / 2);
}

.before\:header-corner::before{
    content: " ";
    background-color: inherit;
    right: 0;
    transform: skewY(var(--corner-angle));
    width: var(--corner-width);
    height: var(--corner-height);
    position: absolute;
    bottom: calc(var(--corner-height) / -2 + var(--corner-width) * var(--tan-corner-angle) / 2);
}
.before\:top-right-corner::before{
    transform: skewY(var(--corner-angle));
    content: " ";
    background-color: inherit;
    right: -1px;
    width: var(--corner-width);
    height: var(--corner-height);
    position: absolute;
    top: calc(-1 * var(--corner-width) * var(--tan-corner-angle) / 2);
}

.before\:header-corner-below::before{
    transform: skewY(var(--corner-angle));
    background-color: inherit;
    content: " ";
    right: -1px;
    width: var(--corner-width);
    height: var(--corner-height);
    position: absolute;
    bottom: calc(-1 * var(--corner-width) * var(--tan-corner-angle) / 2);

}

.fit-cover{
    object-fit:cover;    
}

.pl-\[corner\] {
    padding-left: var(--corner-width);
}

.pr-\[corner\] {
    padding-right: var(--corner-width);
}

/* Backup COLORS */

:root {
    --c-light-default: #FAF8FB;
    --c-light-accent-default: #D69F55;
    --c-dark-default: #18171E;
    --c-dark-accent-default: #7F7A96;
    --c-primary: #A42F07;
}
  

$colors: light, dark, light-accent, dark-accent, primary;

@each $c in $colors {
    .text-#{$c}{
        color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .bg-#{$c}{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .before\:bg-#{$c}::before{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .after\:bg-#{$c}::after{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-b-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-t-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-l-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-r-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:border-#{$c}:hover{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:border-b-#{$c}:hover{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:border-t-#{$c}:hover{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:border-l-#{$c}:hover{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:border-r-#{$c}:hover{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:bg-#{$c}:hover{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .active\:bg-#{$c}:active{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .focus\:bg-#{$c}:focus{
        background-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .hover\:text-#{$c}:hover{
        color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .border-#{$c}{
        border-color: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .to-#{$c}{
        --tw-gradient-to: var(--c-#{$c}, var(--c-#{$c}-default));
    }
    .overlay-gradient-#{$c} {
        background: linear-gradient(to top,var(--c-#{$c}, var(--c-#{$c}-default)) 0%, transparent 30%, transparent 70%, var(--c-#{$c}-default) 100%);
    }
    .overlay-gradient-#{$c}-top {
        background: linear-gradient(to top, transparent 0%, transparent 70%, var(--c-#{$c}-default) 100%);
    }
    .overlay-gradient-#{$c}-bottom {
        background: linear-gradient(to top,var(--c-#{$c}, var(--c-#{$c}-default)) 0%, transparent 30%, transparent 100%);
    }
    .logo-#{$c} .st0{
        fill:var(--c-#{$c}, var(--c-#{$c}-default))
    }
}

@layer components {

    body{
        @apply text-dark bg-light
    }

    p{
        @apply lg:text-lg
    }

    h1,h2,h3,h4,h5,h6 {
        @apply mt-0 mb-2 break-words
    }

    h1{
        @apply text-5xl lg:text-6xl font-semibold
    }
    h2{
        @apply text-4xl lg:text-5xl font-semibold
    }
    h3{
        @apply text-2xl lg:text-3xl font-semibold
    }

    input:invalid{
        @apply border-red-800 border-2
    }
    
    .btn {
        @apply  border-2 p-3 px-8 rounded-tl-xl rounded-br-xl text-lg lg:text-xl font-worksans font-semibold
    }

    .btn-primary {
        @apply bg-primary text-light hover:text-primary hover:bg-transparent border-primary
    }

    .btn-light{
        @apply bg-light text-dark hover:text-light hover:bg-transparent border-light
    }

    .btn-dark{
        @apply bg-dark text-light hover:text-dark hover:bg-transparent border-dark
    }

    .btn-dark-accent{
        @apply bg-dark-accent text-light hover:text-dark-accent hover:bg-transparent border-dark-accent
    }

    .btn-light-accent{
        @apply bg-light-accent text-dark hover:text-light-accent hover:bg-transparent border-light-accent
    }

    .btn-primary-open{
        @apply bg-transparent text-primary hover:text-light hover:bg-primary border-primary
    }

    .btn-light-open {
        @apply bg-transparent text-light hover:text-dark hover:bg-light border-light 
    }

    .btn-dark-open {
        @apply bg-transparent text-dark hover:text-light hover:bg-dark border-dark
    }

    .btn-dark-accent-open{
        @apply bg-transparent text-dark-accent hover:text-light hover:bg-dark-accent border-dark-accent
    }

    .btn-light-accent-open{
        @apply bg-transparent text-light-accent hover:text-dark hover:bg-light-accent border-light-accent
    }


    .btn.disabled{
        @apply bg-slate-300 text-slate-400 hover:bg-slate-300 hover:text-slate-400 border-slate-300;
    }
    .bg-img img{
        object-fit: cover;
        object-position:50% 50%;
        max-width: none;
        min-width:100%;
        min-height:120%;
    }
    
    .home-album-img img{
        height:100%;
        object-fit:cover;
    }

    .home-album-img:nth-child(even){
        margin-left:-3em;
    }

    .f-container {
        width: 100%;
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .text-container {
        width: 100%;
        padding-left: 3vw;
        padding-right: 3vw;
    }

    @screen md{
        .f-container {
            padding-left: 8vw;
            padding-right: 8vw;
        }
        .text-container {
            padding-left: 8vw;
            padding-right: 8vw;
        }
        .home-album-img:nth-child(even){
            margin-left:0;
        }
        .home-album-img:nth-child(3n){
            margin-left:-3em;
        }
        .home-album-img:nth-child(3n-2){
            margin-left:-4em;
        }
    }

    @screen lg {
        .f-container {
            padding-left: 8vw;
            padding-right: 8vw;
        }
        .text-container {
            padding-left: 20vw;
            padding-right: 20vw;
        }
    }

    @screen xl {
        .f-container {
            padding-left: 18vw;
            padding-right: 18vw;
        }
        .text-container {
            padding-left: 24vw;
            padding-right: 24vw;
        }
    }

    #components *:nth-child(odd) .component {
        @apply  before:bg-light text-dark;
    }

    #components *:nth-child(even) .component{
        @apply before:bg-dark text-light;
    }

    .overlay-gradient{
        @apply overlay-gradient-dark;
    }
    .overlay-gradient-top{
        @apply overlay-gradient-dark-top;
    }
    .overlay-gradient-bottom{
        @apply overlay-gradient-dark-bottom;
    }

    // Printing 
    @media print {
        .pagebreak {
            clear: both;
            page-break-after: always;
        }
        table { page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }
    }
}

// Animations
